
    import { computed, defineComponent, ref } from 'vue'

    export default defineComponent({
        props: {
            success: {
                type: Boolean,
                required: true
            },
            id: {
                type: String,
                required: false
            },
            updateKey: {
                type: String,
                required: false
            },
            message: {
                type: String,
                required: false
            }
        },
        setup(props) {
            const publicCopied = ref(false);
            const privateCopied = ref(false);

            const Result = computed(() => {
                return props.success ? "Success" : "Error";
            });

            const shareUrl = computed(() => {
                return window.location.origin + "/" + props.id;
            });

            const privateUrl = computed(() => {
                return window.location.origin + "/" + props.id + "?key=" + props.updateKey;
            });

            const copy = (event: Event) => {
                const eventTarget = event.target as HTMLParagraphElement;
                if (eventTarget.id === "public")
                    publicCopied.value = true;
                else
                    privateCopied.value = true;

                const elm = document.createElement('textarea');
                elm.value = eventTarget.innerText;
                document.body.appendChild(elm);
                elm.select();
                document.execCommand('copy');
                document.body.removeChild(elm);
                setTimeout(() => {
                    publicCopied.value = false;
                    privateCopied.value = false;
                }, 1200);
            }

            const showCopied = computed(() => {
                return publicCopied.value;
            })

            return { Result, shareUrl, privateUrl, copy, publicCopied, privateCopied, showCopied };
        }
    })
