
    import { defineComponent } from 'vue';

    export default defineComponent({
        data() {
            return {
                exploreHidden: true
            }
        }
    })
