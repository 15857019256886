
import { defineComponent } from 'vue';
import VueSlider from 'vue-slider-component';
import Loader from '@/components/Loader.vue';
import SaveModal from '@/components/SaveModal.vue';
import ExploreBar from '@/components/ExploreBar.vue';
import 'vue-slider-component/theme/antd.css';
import MapToGlobe from '../assets/MapToGlobe/MapToGlobe';
import * as Firebase from '../firebase';

interface CanvasElement extends HTMLCanvasElement {
    captureStream(frameRate?: number): MediaStream;
}

export default defineComponent({
    components: {
        VueSlider,
        Loader,
        SaveModal,
        ExploreBar
    },
    data() {
        return {
            loading: false,
            loader: {
                message: null
            },
            saveModal: {
                show: false,
                success: false,
                id: "",
                key: "",
                message: ""
            },
            menu: {
                open: {
                    planet: false,
                    moon: false,
                    rings: false,
                    background: false,
                    lights: false,
                    gif: false,
                    screenshot: false
                },
                planet: {
                    shininess: 60
                },
                moon: {
                    controls: false,
                    scale: 1,
                    distance: 3
                },
                light: {
                    sunIntensity: 0.4,
                    ambientIntensity: 0.6
                }
            },
            images: {
                surface: false,
                clouds: false
            },
            atmosphere: {
                enabled: false
            },
            moonIsVisible: false,
            ringsVisible: false,
            controls: null,
            maptoglobe: {} as MapToGlobe,
            loadedJson: {}
        }
    },
    created() {
        this.$watch(
            () => this.$route.params,
            () => { if (this.$route.params.saveId.length > 0) this.Load(this.$route.params.saveId as string) },
            { immediate: true }
        )
    },
    async mounted() {
        window.addEventListener("set_loading_message", (e: CustomEventInit) => {
            this.loader.message = e.detail;
        });
        
        this.maptoglobe = new MapToGlobe(document.getElementById("scene") as HTMLCanvasElement);

        this.menu.planet.shininess = ((this.maptoglobe.planet.object.material as THREE.Material[])[0] as THREE.MeshPhongMaterial).shininess;
        this.menu.light.sunIntensity = (this.maptoglobe.instance.light.children[0] as THREE.DirectionalLight).intensity;
        this.menu.light.ambientIntensity = this.maptoglobe.instance.ambient.intensity;
        this.menu.moon.distance = this.maptoglobe.moon.moon.position.x;
        this.menu.moon.scale = this.maptoglobe.moon.moon.scale.x;
    },
    methods: {
        setSurfaceImage(event: Event) {
            Firebase.analytics.logEvent("set_planet_surface");
            const files = (event.target as HTMLInputElement).files;
            if (files !== null) {
                this.maptoglobe.planet.SetSurfaceImage(files[0]);
                this.images.surface = true;
            }
        },
        setHeightmapImage(event: Event) {
            Firebase.analytics.logEvent("set_planet_heightmap");
            const files = (event.target as HTMLInputElement).files;
            if (files !== null) {
                this.maptoglobe.planet.SetHeightmapImage(files[0]);
            }
        },
        setSpecularImage(event: Event) {
            Firebase.analytics.logEvent("set_planet_specular");
            const files = (event.target as HTMLInputElement).files;
            if (files !== null) {
                this.maptoglobe.planet.SetSpecularImage(files[0]);
            }
        },
        toggleAxis() {
            Firebase.analytics.logEvent("toggle_planet_axis");
            this.maptoglobe.planet.ToggleAxis();
        },
        toggleAtmosphere() {
            //this.maptoglobe.planet.ToggleAtmosphere();
            return;
        },
        setCloudsImage(event: Event) {
            Firebase.analytics.logEvent("set_planet_clouds");
            const files = (event.target as HTMLInputElement).files;
            if (files !== null) {
                this.maptoglobe.planet.SetCloudsImage(files[0]);
                this.images.clouds = true;
            }
        },
        planetShininess(value: number) {
            ((this.maptoglobe.planet.object.material as THREE.Material[])[0] as THREE.MeshPhongMaterial).shininess = value;
        },
        toggleMoon() {
            Firebase.analytics.logEvent("toggle_moon");
            this.moonIsVisible = !this.moonIsVisible;
            if (this.moonIsVisible) {
                this.maptoglobe.AddMoon();
            }
            else {
                this.maptoglobe.RemoveMoon();
            }
        },
        setMoonImage(event: Event) {
            Firebase.analytics.logEvent("set_moon_surface");
            const files = (event.target as HTMLInputElement).files;
            if (files !== null) {
                this.maptoglobe.moon.SetSurfaceImage(files[0]);
            }
        },
        toggleRings() {
            Firebase.analytics.logEvent("toggle_rings");
            this.ringsVisible = !this.ringsVisible;
            this.maptoglobe.ToggleRings();
        },
        setRingsImage(event: Event) {
            Firebase.analytics.logEvent("set_rings_surface");
            const files = (event.target as HTMLInputElement).files;
            if (files !== null) {
                this.maptoglobe.rings.SetSurfaceImage(files[0]);
            }
        },
        setRingsTransparency(event: Event) {
            Firebase.analytics.logEvent("set_rings_transparency");
            const files = (event.target as HTMLInputElement).files;
            if (files !== null)
                this.maptoglobe.rings.SetTransparencyImage(files[0]);
        },
        setBgBlack() {
            Firebase.analytics.logEvent("set_bg_black");
            this.maptoglobe.SetBGBlack();
        },
        setBgTransparent() {
            Firebase.analytics.logEvent("set_bg_transparent");
            this.maptoglobe.SetBGTransparent();
        },
        setBgImage(event: Event) {
            Firebase.analytics.logEvent("set_bg_image");
            (document.querySelector("#imageBG + input") as HTMLInputElement).checked = true;
            const files = (event.target as HTMLInputElement).files;
            if (files !== null) {
                this.maptoglobe.SetBGImage(files[0]);
            }
        },
        toggleControls(event: Event) {
            const target = (event.target as HTMLButtonElement).value;
            switch (target) {
                case "planet":
                    Firebase.analytics.logEvent("toggle_planet_controls");
                    this.maptoglobe.ToggleControls(this.maptoglobe.planet.object);
                    break;
                case "rings":
                    Firebase.analytics.logEvent("toggle_rings_controls");
                    this.maptoglobe.ToggleControls(this.maptoglobe.rings.object);
                    break;
                case "light":
                    Firebase.analytics.logEvent("toggle_light_controls");
                    this.maptoglobe.ToggleControls(this.maptoglobe.instance.light as THREE.Mesh);
                    break;
                case "orbit":
                    Firebase.analytics.logEvent("toggle_orbit_controls");
                    this.maptoglobe.ToggleControls(this.maptoglobe.moon.object as THREE.Mesh);
                    break;
                case "rotation":
                    Firebase.analytics.logEvent("toggle_rotation_controls");
                    this.maptoglobe.ToggleControls(this.maptoglobe.moon.moon);
                    break;
                case "clouds":
                    Firebase.analytics.logEvent("toggle_clouds_controls");
                    this.maptoglobe.ToggleControls(this.maptoglobe.planet.object.getObjectByName("clouds") as THREE.Mesh);
            }
        },
        moonScale(value: number) {
            this.maptoglobe.moon.Scale(value);
        },
        moonDistance(value: number) {
            this.maptoglobe.moon.Distance(value);
        },
        sunIntensity(value: number) {
            this.maptoglobe.instance.SetSunIntensity(value);
        },
        ambientIntensity(value: number) {
            this.maptoglobe.instance.SetAmbientIntensity(value);
        },
        makeGif() {
            Firebase.analytics.logEvent("make_gif");
            this.maptoglobe.Gif(document.getElementById("scene") as CanvasElement);
        },
        takeScreenshot() {
            Firebase.analytics.logEvent("take_screenshot");
            this.maptoglobe.Screenshot(document.getElementById("scene") as CanvasElement);
        },
        async save() {
            Firebase.analytics.logEvent("save");
            this.loading = true;
            const response = await this.maptoglobe.Save(this.$route, this.loadedJson);
            if (response.success){ 
                this.saveModal.success = true;

                if (response.id && response.key) {
                    this.saveModal.id = response.id;
                    this.saveModal.key = response.key;

                    this.$router.push({ name: "Home", params: { saveId: response.id }, query: { key: response.key } });
                }
                if (response.message)
                    this.saveModal.message = response.message;
            }
            else {
                this.saveModal.success = false;
                if (response.message)
                    this.saveModal.message = response.message;
            }
            this.saveModal.show = true;
            this.loading = false;
        },
        async Load(item: string) {
            const event = new CustomEvent('set_loading_message', { detail: `Loading` });
            window.dispatchEvent(event);

            this.loading = true;
            const data = await Firebase.Get(item);

            if (data.success) {
                this.loadedJson = data.data;
                this.images.surface = true;
                await this.maptoglobe.Load(data.data);

                this.menu.planet.shininess = ((this.maptoglobe.planet.object.material as THREE.Material[])[0] as THREE.MeshPhongMaterial).shininess;
                this.menu.light.sunIntensity = (this.maptoglobe.instance.light.children[0] as THREE.DirectionalLight).intensity;
                this.menu.light.ambientIntensity = this.maptoglobe.instance.ambient.intensity;
                this.menu.moon.distance = this.maptoglobe.moon.moon.position.x;
                this.menu.moon.scale = this.maptoglobe.moon.moon.scale.x;
            }
            else {
                this.saveModal.success = false;
                this.saveModal.message = data.message as string;
                this.saveModal.show = true;
            }
            this.loading = false;
        }
    }
})
