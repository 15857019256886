
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    message: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const LoadingMessage = computed(() => {
      return props.message ? props.message : "Loading";
    });

    return { LoadingMessage };
  }
})
